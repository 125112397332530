/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";

body {
  margin: 0;
  font-family: sans-serif;
}

h1, h2 {
  text-align: center;
}

.input-group {
  display: flex;
  align-items: stretch;
}

.input-group-field {
  margin-right: 0;
}

.input-group .input-group-button {
  margin-left: 0;
  border: none;
}

.input-group .mat-flat-button {
  border-radius: 0;
}

.upowa-color-primary {
  background-color: #f39301;
  color: #fff;
}
.upowa-color-last {
  background-color: #6a4e38;
  color: #fff;
}

.red-color {
  color: #cb3429;
}

.green-color{
  color: #17b01f;
}

.margin {
  margin: 20px;
}

.padding {
  padding: 10px;
}

.p-d {
  padding-bottom: 5px;
}

.responsive-item {
  width: 100%;
  height: auto;
}
.responsive-item-maw {
  width: 100%;
  max-width: 100px;
  height: auto;
}

.mt-5 {
  margin-top: 50px;
}

.container {
  min-height: 100%;
  width: 100%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid green;
  height: 100%;
}

.add-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
}
.delete-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.make-overflow-answer {
  max-height: 90vh;
  min-height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.close{
  float:right;
}
.bold {
  font-weight: bold;
}

button.close{
  padding:0;
  background-color:transparent;
  border:0;
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none
}
a.close.disabled{
  pointer-events:none
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #f39301 !important;
  border-radius: 5px;
}

.errorMessage {
  color: #cb3429;
}

.p-10 {
  padding: 10px;
}


.form {
  min-width: 150px;
  width: 100%;
}

.form-element {
  width: 100%;
}

.button {
  width: 100%;
}

.radio-btn {
  padding: 0 5px 0 5px;
}
